<template>
  <v-dialog persistent v-model="dialog" scrollable width="auto">
    <v-card light color="white">
      <v-card-title class="dialog__header">
        <span
          class="headline font-weight-medium"
        >
            {{ $t('documents.titles.versions') }}
          </span>
      </v-card-title>
      <v-divider />
        <v-divider />
        <v-card-text class="pa-6">
          <v-row justify="space-between" v-if="dialog">
            <v-col
              cols="12"
            >
              <v-data-table
                class="elevation-0"
                item-key="id"
                show-expand
                :headers="headers"
                :items="versions"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalFromServer"
                :single-expand="false"
                sort-by="numberVersion"
                :no-results-text="$t('common.ui.not_search_found')"
                :no-data-text="$t('common.ui.not_data_found')"
                :loading-text="$t('common.notification.loadData')"
                :footer-props="{
                        ...rowsPerPageItemsOptions,
                        itemsPerPageText: $t('common.table.itemsPerPageText'),
                        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                      }"
                @item-expanded="loadVersion($event)"
              >
                <template v-slot:item.active='{ item }'>
                  <v-icon small class='mr-4' color='success' v-if='item.active'>
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <v-icon small class='mr-4' color='metal' v-if='!item.active'>
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="neuter"
                        small
                        class="mr-2"
                        @click="showVersion(item)"
                        v-if="check([{ domain: 'Location', permisions: ['Read'] }])"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </template>
                    <span>
                            {{ $t("common.buttons.show") }}
                            <span style="font-size: 12px; color: #AAAAAA">
                              {{ $t("locations.label") }}
                            </span>
                          </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="check([{ domain: 'Location', permisions: ['Update'] }]) && !item.isCurrentVersion"
                        color="neuter"
                        class="mr-2"
                        small
                        @click="editVersion(item)"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>
                            {{ $t("common.buttons.edit") }}
                            <span style="font-size: 12px; color: #AAAAAA">
                              {{ $t("locations.titles.locations") }}
                            </span>
                          </span>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" style="height: 20vh" class="pa-0">
                    <div class="map">
                      <v-overlay :value="!ready[item.id]" absolute light color="white" opacity=".8">
                        <v-progress-circular
                          indeterminate
                          size="64"
                          color="primary"
                        />
                      </v-overlay>
                      <map-box
                        :displayDrawControlsDefault="false"
                        :drawControl="true"
                        :drawControls="controls"
                        :geolocateControl="true"
                        :mapConfiguration="mapConfig"
                        :navigationControl="true"
                        :oneLocation="true"
                        :scaleControl="true"
                        :searchControl="false"
                        ref="mapLocations"
                        :fullScreenControl="false"
                        v-on:areaChange="setDataFormLocations($event)"
                        :confStyle="'satellite-v9'"
                        :mapHeight="45"
                        v-on:loadComplete="initializeMap(item)"
                        v-on:styleLoad="reload"
                        :actionStyle="false"
                      />
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-6">
          <v-spacer />
          <v-btn
            small elevation="0" color="primary" class="t-bw-primary--text"
            @click="closeVersionsDialog()"
          >
            {{ $t('common.buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";
import LanguageService from "@/services/LanguajeService";
import MapBox from "@/components/vueMapbox/MapBox.vue";
import {mapboxMixin} from "@/mixins/mapbox.mixin";
import PermisionService from "@/services/PermisionService";

export default {
  name: "VersionsComponent",
  components: {
    MapBox
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
  },

  mixins: [mapboxMixin],

  data: () => ({
    options: {},
    ready: {},
    loading: false,
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    controls: {
      polygon: false,
      point: false,
      trash: false,
    },
    editedItem: {},
    selectedItem: {},
    defaultFilters: {
      stringFilter: null,
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'id',
        direction: 'ASC',
      }
    },
    location: null
  }),

  watch: {
    options: {
      async handler() {
        this.loading = true;

        await this.fetchListVersions([{
          locationId: this.location.locationId,
          pageableDTO: {
            page: this.options.page - 1,
            size: this.options.itemsPerPage,
            sortBy: 'versionNumber',
            direction: 'ASC',
          }
        }, this.$toast]).finally(() => this.loading = false)
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      totalFromServer: 'locations/totalFromServerVersions',
    }),
    versions: {
      get() {
        return this.$store.getters['locations/versions']
      },
      set(val) {
        this.$store.commit('locations/SET_VERSIONS_LIST', val)
      },
    },
    headers: () => [
      {text: i18n.t("locations.fields.name"), value: "name", sortable: false},
      {text: i18n.t("locations.fields.address1"), value: "address1", sortable: false},
      {text: i18n.t("locations.fields.telephone"), value: "telephone"},
      {text: i18n.t("locations.fields.email"), value: "email"},
      {text: i18n.t("locations.fields.gln"), value: "gln"},
      {text: i18n.t("locations.fields.area"), value: "area", width: 120},
      {
        text: i18n.t("locations.fields.active"),
        value: "active",
        align: "center",
        width: 120,
      },
      {
        text: i18n.t('common.fields.action'),
        value: 'action',
        sortable: false,
        align: 'start'
      },
      { text: '', value: 'data-table-expand' }
    ],
  },

  mounted() {
    this.$root.$on('location:versions', (item) => {
      this.location = Object.assign({}, item);
    })
  },

  methods: {
    ...mapActions({
      fetchLocationById: "locations/fetchLocationById",
      fetchListVersions: 'locations/fetchListVersions',
    }),
    showVersion(item) {
      localStorage.setItem('location_editOrSave', item.id)
      localStorage.setItem('location:isVersion', JSON.stringify(1));
      this.$router.push({ name: 'location-management', params: { uuid: item.id, action: 'show' } })
    },
    async editVersion(item) {
      localStorage.setItem('location_editOrSave', item.id)
      localStorage.setItem('location:isVersion', JSON.stringify(1));
      this.$router.push({ name: 'location-management', params: { uuid: item.id, action: 'edit' } })
    },
    check(permisions) {
      return PermisionService.check(permisions);
    },
    closeVersionsDialog() {
      this.$emit('close:dialog', false)
      this.location = null;
      this.$store.commit('locations/RESTORE_VERSIONS_DATA')
    },
    async loadVersion(e) {
      if (e) {
        if (e.value) {
          this.$set(this.ready, e.item.id, false)

          this.selectedItem = await this.fetchLocationById([e.item.id, this.$toast])

          this.editedIndex = this.versions.indexOf(e.item);
          this.editedItem = Object.assign({}, this.selectedItem);
          this.editedItem.description_name = LanguageService.getKey3(this.editedItem.description)
        } else {
          this.editedItem = {}
          this.selectedItem = {}
          this.$set(this.ready, e.item.id, true)
        }
      }
    },
    async initializeMap(item) {
      await this.reload(true).finally(() => {
        this.$set(this.ready, item.id, true)
      });
    },
  },

  destroyed() {
    this.$emit('close:dialog', false)
    this.closeVersionsDialog();
  }
}
</script>

<style scoped>
.map {
  height: 300px;
  overflow: hidden;
  position: relative;
}

.map img {
  position: absolute; /* Posiciona la imagen de forma absoluta */
  top: 50%; /* Centra la imagen verticalmente */
  left: 50%; /* Centra la imagen horizontalmente */
  width: 110%; /* Aumenta el ancho */
  height: 110%; /* Aumenta el alto */
  object-fit: cover;
  transform: translate(-50%, -50%); /* Desplaza la imagen para centrarla */
}
</style>